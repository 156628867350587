import axios from "axios";
import React, { useState } from "react";

import { API_URL } from "../../constants";
// notification
import { ToastNotification } from "../../utils/ToastNotification";

const JadeReservation = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [noOfPax, setNoOfPax] = useState(0);
  const [noOfChildren, setNoOfChildren] = useState(0);
  const [dateOfCheckIn, setDateOfCheckIn] = useState("");
  const [dateOfCheckOut, setDateOfCheckOut] = useState("");
  const [timeOfArrival, setTimeOfArrival] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");

  const submitReservation = (event) => {
    event.preventDefault();
    let data = JSON.stringify({
      reserved_by: `${firstName} ${lastName}`,
      checkin_date: dateOfCheckIn,
      checkout_date: dateOfCheckOut,
      checkin_time: timeOfArrival,
      reserver_contact: contactNumber,
      no_of_pax: noOfPax,
      no_of_children: noOfChildren,
      special_requests: specialRequests,
    });

    // for utsav: this is data for jade reservation
    // *★,°*:.☆(￣▽￣)/$:*.°★* 。~

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}book/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${window.btoa("frontend:WWEQgN9.%AAw]GfR")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        ToastNotification.fire({
          icon: "success",
          // timer: 30000,
          title: "Reservation Successful!",
        });
      })
      .catch((error) => {
        console.log(error);
        ToastNotification.fire({
          icon: "warning",
          // timer: 3000000,
          title: "Reservation Failed!",
        });
      });
  };

  return (
    <>
      <img
        className="w-[30%] mx-auto py-4"
        src={require("../../assets/images/trisara jade-01.png")}
        alt="Jade Logo"
      />
      <h1 className="text-center text-2xl">Book a room</h1>
      <form className="md:px-8 px-3" onSubmit={submitReservation}>
        {/* FName | LName */}
        <div className="mt-6 grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <input
              type="text"
              name="first_name"
              id="first_name"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder=" "
              required
            />
            <label
              htmlFor="first_name"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              First name <span className="text-rose-500">*</span>
            </label>
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <input
              type="text"
              name="last_name"
              id="last_name"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder=" "
              required
            />
            <label
              htmlFor="last_name"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              Last name <span className="text-rose-500">*</span>
            </label>
          </div>
        </div>

        {/* Contact Number */}
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="number"
            pattern="[0-9]{10}"
            name="phone"
            id="phone"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            placeholder=" "
            required
          />
          <label
            htmlFor="phone"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            Contact Number (98xxxxxxxx) <span className="text-rose-500">*</span>
          </label>
        </div>

        {/* Outlet pick */}
        {/* <div className="group relative z-0 mb-6 w-full">
        <select
          id="underline_select"
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm focus:border-gray-600 focus:outline-none focus:ring-0"
          value={outlet}
          onChange={(e) => setOutlet(e.target.value)}
          required
        >
          <option value="" hidden disabled>
            Pick an Outlet *
          </option>
          {outlets_choices ? (
            <OutletOptions outletChoices={outlets_choices} />
          ) : outlets_choices_error ? (
            <option disabled>{outlets_choices_error}</option>
          ) : (
            <option disabled>
              <Loader />
            </option>
          )}
        </select>
      </div> */}

        {/* Number of people | children */}
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="group relative z-0 mb-6 w-full">
            <input
              type="number"
              name="people"
              id="people"
              min="1"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={noOfPax}
              onChange={(e) => setNoOfPax(e.target.value)}
              placeholder=" "
              required
            />
            <label
              htmlFor="people"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              No of Pax <span className="text-rose-500">*</span>
            </label>
          </div>
          <div className="group relative z-0 mb-6 w-full">
            <input
              type="number"
              name="children"
              id="children"
              min="0"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={noOfChildren}
              onChange={(e) => setNoOfChildren(e.target.value)}
              placeholder=" "
            />
            <label
              htmlFor="children"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              No of Children
            </label>
          </div>
        </div>

        {/* Time */}
        <div className="group relative z-0 mb-6 w-full">
          <div
            className="relative"
            data-te-timepicker-init
            data-te-input-wrapper-init
          >
            <input
              type="time"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={timeOfArrival}
              onChange={(e) => setTimeOfArrival(e.target.value)}
              id="time"
              required
            />
            <label
              htmlFor="time"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              Select a time <span className="text-rose-500">*</span>
            </label>
          </div>
        </div>

        {/* Checkin Checkout */}
        <div className="grid md:grid-cols-2 md:gap-6">
          <div className="relative mb-6 max-w-sm">
            <input
              datepicker="true"
              type="date"
              id="date"
              value={dateOfCheckIn}
              onChange={(e) => setDateOfCheckIn(e.target.value)}
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              required
            />
            <label
              htmlFor="date"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              Check-in Date <span className="text-rose-500">*</span>
            </label>
          </div>
          <div className="relative mb-6 max-w-sm">
            <input
              datepicker="true"
              type="date"
              id="date"
              value={dateOfCheckOut}
              onChange={(e) => setDateOfCheckOut(e.target.value)}
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              required
            />
            <label
              htmlFor="date"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              Check-out Date <span className="text-rose-500">*</span>
            </label>
          </div>
        </div>

        {/* Special Requests */}
        <div className="relative mb-3" data-te-input-wrapper-init>
          <textarea
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            rows="3"
            id="specialRequests"
            value={specialRequests}
            onChange={(e) => setSpecialRequests(e.target.value)}
            placeholder=" "
          ></textarea>
          <label
            htmlFor="specialRequests"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            Special Requests
          </label>
        </div>

        <button
          type="submit"
          className="mx-auto block w-full rounded-lg bg-green px-5 py-2.5 text-center text-sm font-medium text-gray-50 hover:bg-green-700 focus:outline-none sm:w-auto"
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default JadeReservation;
