// import React from "react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// importing images
import landing from "../assets/images/landing.png";
import mainLogo from "../assets/images/trisara main.png";
import Loader from "../components/general/loader";
import { API_URL } from "../constants";
import "../styles/newHome.css";
// for db.json
import useFetch from "../utils/useFetch";
import NotFound from "./general/notFound";

const Home = () => {
  // fetching data
  const { data: events, error: events_error } = useFetch(
    `${API_URL}events/upcoming`
  );
  const { data: franchises, error: franchise_error } = useFetch(
    `${API_URL}franchises`
  );

  // for pop up
  const MySwal = withReactContent(Swal);

  // show more text
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (events !== null && events.length > 0) {
      MySwal.fire({
        html: (
          <>
            {events ? (
              <>
                {events.map((event) => (
                  <div key={event.id}>
                    {event.media_type === "image" ? (
                      <img
                        className="max-h-[98vh]"
                        src={event.media}
                        alt={`${event.name} on ${event.date}`}
                      />
                    ) : event.media_type === "video" ? (
                      <video
                        src={event.media}
                        alt={`${event.name} on ${event.date}`}
                        muted
                        autoPlay={true}
                        loop
                      />
                    ) : (
                      <p>Media not supported.</p>
                    )}

                    {/* for video */}
                  </div>
                ))}
              </>
            ) : events_error ? (
              <p className="pt-32 text-center">{events_error}</p>
            ) : (
              <p className="pt-32 text-center">
                <Loader />
              </p>
            )}
          </>
        ),
        showConfirmButton: false,
        showCloseButton: true,
        width: 1000,
      });
    }
  }, [events, events_error, MySwal]);

  let data = [
    {
      text: "Trisara, the third garden of heaven, where passion for culinary excellence is matched only by the commitment to exceptional service; with the chic casual dining layout boast a unique design and service style that somehow gel to create memorable experiences for all. From the aroma of freshly baked goods & the scrumptious food, stay track in a good music and with special charming ambience & exceptional service to your ultimate destination for luxury, comfort and exceptional hospitality, Trisara got you covered for an unforgettable stay & everlasting aftertaste.",
    },
  ];

  return (
    // <div className="h-screen snap-y snap-mandatory overflow-y-scroll" dir="ltr">
    <div className="h-screen" dir="ltr">
      {franchises ? (
        <>
          <div className="h-screen snap-start">
            <section className="landing ">
              <img
                src={landing}
                alt="landing"
                className="bg-img bg-[#528b3c]"
              />
              <div className="content">
                <div className="content-wrapper">
                  <img
                    src={mainLogo}
                    alt="trisara logo"
                    className="mx-auto w-[80vw] sm:w-[50vw] lg:w-[30vw]"
                  />
                  <h1 className="logo-text home-white-text text-[20px] xl:text-[27px]">
                    The Third Garden of Heaven
                  </h1>
                  <p className="home-white-text mx-auto px-3 pt-2 sm:p-8 md:px-12 lg:w-[90vw] xl:w-[50vw]">
                    <b>Welcome to Trisara.</b>
                    <br />
                    <br />
                    With our commitment to craft, feel the immersive experiences
                    & bespoke service.
                    <br />
                    <br />
                    {data.map((item, index) => (
                      <div key={index}>
                        {showMore ? (
                          <p className="inline">{item.text}&nbsp;</p>
                        ) : (
                          <p className="inline">
                            {item.text.substring(0, 300)} ....&nbsp;
                          </p>
                        )}
                        <span
                          className="text-white font-bold hover:underline text-lg cursor-pointer imac:text-4xl"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Show less" : "Show more"}
                        </span>
                      </div>
                    ))}
                  </p>
                  <button>
                    <Link
                      className="home-button-white"
                      to="/trisara/t_lazimpat/menu"
                    >
                      View Menu
                    </Link>
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="grid lg:grid-cols-6 grid-cols-1 gap-[5px] lg:pt-0 pt-[5px]">
            {/* <div className="grid grid-cols-1 gap-y-[5px] pt-[5px]"> */}
            {franchises.map((franchise, index) => (
              <>
                <a
                  href={
                    franchise.coming_soon
                      ? "/openingsoon"
                      : franchise.id === "peros"
                        ? `/${franchise.id}/${franchise.outlets[0].id}`
                        : franchise.id === "jade"
                          ? "https://www.jadecity.com.np"
                          : ""
                            ? franchise.id === "trisara" ||
                              franchise.id === "bakery"
                            : `/${franchise.id}`
                  }
                  target={franchise.id === "jade" ? "_blank" : ``}
                  rel="noreferrer"
                  className="grid-item group relative overflow-hidden"
                  key={index}
                >
                  <div className="grid-image" key={franchise.id}>
                    <img
                      alt={`${franchise.name} franchise`}
                      src={franchise.image_thumbnail}
                      className="thumbnail-image h-screen w-full cursor-pointer object-cover  transition-transform duration-[10s] ease-in-out group-hover:scale-125 "
                    />
                  </div>
                  <div className="titles absolute bottom-0 z-10 flex h-full w-full flex-col items-center justify-center gap-5 bg-black bg-opacity-40 text-center transition-all duration-700 lg:h-40 imac:h-[15vh] lg:bg-[#eee] lg:bg-opacity-100 lg:group-hover:h-60 imac:group-hover:h-[20vh]">
                    <img
                      alt="logo"
                      className="md:h-[8vh] object-contain lg:bg-[#00000000] bg-[#e0e0e0d2] bg-opacity-100 p-3 rounded-lg"
                      src={franchise.logo}
                    />
                    <p className="mx-8 hidden p-3 text-center imac:text-4xl text-sm capitalize text-black opacity-0 xl:group-hover:block xl:group-hover:animate-slow-opacity">
                      {franchise.about}
                    </p>
                  </div>
                </a>
              </>
            ))}
          </div>
        </>
      ) : franchise_error ? (
        <NotFound />
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </div>
  );
};

export default Home;
