import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Loader from "../components/general/loader";
import Nav from "../components/nav";
import Achievements from "../pages/achievementsHome";
import CareerDesc from "../pages/careers/careerDesc";
import Careers from "../pages/careers/careers";
import Events from "../pages/events";
// general
import NotFound from "../pages/general/notFound";
import OpeningSoon from "../pages/general/openingSoon";
import Home from "../pages/home";
// for testing
import Playground from "../pages/playground/playground";
import Playground1 from "../pages/playground/playground1";
import Playground2 from "../pages/playground/playground2";
import Menu1 from "../pages/playground/trisaraMenu";
// trisara
import Trisara from "../pages/trisara/trisara";
import Menu from "../pages/trisara/trisaraMenu";
import TrisaraOutlet from "../pages/trisara/trisaraOutlet";
import ToTop from "./scrollToTop";

const Routing = () => {
  return (
    <Router>
      <ToTop />
      <Nav />

      <Routes>
        {/* pages */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/loader" element={<Loader />} />
        <Route exact path="/achievements" element={<Achievements />} />

        {/* trisara */}
        <Route exact path="/:id" element={<Trisara />} />
        <Route exact path="/:id/:id" element={<TrisaraOutlet />} />
        <Route exact path="/:franchiseId/:outletId/menu" element={<Menu />} />

        {/* others */}
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/careers/:id" element={<CareerDesc />} />
        <Route exact path="/events" element={<Events />} />

        {/* general */}
        <Route exact path="/openingSoon" element={<OpeningSoon />} />
        <Route exact path="/playground" element={<Playground />} />
        <Route exact path="/playground1" element={<Playground1 />} />
        <Route exact path="/playground2" element={<Playground2 />} />
        <Route
          exact
          path="/:franchiseId/:outletId/playgroundmenu"
          element={<Menu1 />}
        />

        {/* default routes for invalid links */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
