import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
// icons import
import { CgBriefcase } from "react-icons/cg";

import Loader from "../../components/general/loader";
import { API_URL } from "../../constants";
// for data fetching
import useFetch from "../../utils/useFetch";

const Careers = () => {
  const { data: vacancies, error } = useFetch(`${API_URL}vacancies`);
  // State for Search Text
  const [searchText, setSearchText] = useState("");

  console.log(vacancies);

  return (
    <div>
      <div className="background-image">
        <img
          src={require("../../assets/images/trisara family photo.png")}
          alt="Top bg"
        />
        <h1 className="font-bold text-5xl py-3 h-full place-content-center">
          Careers
        </h1>
      </div>
      {/* search */}
      <div className="search-bar relative h-12 items-center mx-auto mt-3 imac:mt-20">
        <input
          type="text"
          className="h-10 rounded-md border-[1px] border-green px-2 py-1 text-sm imac:h-24 imac:text-3xl"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <AiOutlineSearch className="absolute right-2 text-sm font-semibold" />
      </div>
      {/* list vacancies */}
      {vacancies ? (
        <>
          {vacancies.length !== 0 ? (
            <div>
              {vacancies
                .filter((unfilteredVacancy) =>
                  unfilteredVacancy.position.toLowerCase().includes(searchText)
                )
                .map((vacancy) => (
                  <a
                    href={`/careers/${vacancy.id}`}
                    key={vacancy.id}
                    className="job-container"
                  >
                    <div className="job-list">
                      <div className="title">
                        <CgBriefcase />
                        <h2 className="ml-2 font-semibold text-xl">
                          {vacancy.position}
                        </h2>
                      </div>
                      <button
                        type="submit"
                        className="btn-apply block rounded-lg bg-green px-5 py-2.5 text-center text-sm font-medium text-gray-50 hover:bg-green-700 focus:outline-none sm:w-auto"
                      >
                        View More
                      </button>
                      <div className="job-description">
                        <div className="tags">
                          <span className="job-details">
                            {vacancy.schedule}
                          </span>
                          <span className="job-details">
                            {vacancy.outlet.franchise.name} -{" "}
                            {vacancy.outlet.name}
                          </span>
                          <span className="job-details">
                            {vacancy.category}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          ) : (
            <div className="text-center font-bold text-xl md:text-3xl imac:text-6xl pt-20 px-5 min-h-[20vh]">
              No vacancies available at the moment.
            </div>
          )}
        </>
      ) : error ? (
        <p className="pt-32 text-center">{error}</p>
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </div>
  );
};

export default Careers;
