import React, { useRef } from "react";
import { HashLink } from "react-router-hash-link";

const Playground = () => {
  const myRefs = useRef([]);
  console.log(myRefs);
  // ref={el => (myRefs.current[i] = el)}

  const handleClick = (item) => {
    // myRefs.current.scrollIntoView({ behavior: "smooth" });
    console.log(item);
  };
  return (
    <div>
      <div className="min-h-screen grid place-content-center">
        {["rose", "blue", "emerald", "yellow", "slate"].map((v, i) => (
          <button
            key={v}
            id={i}
            onClick={() => handleClick(v)}
          >{`Go to ${v}`}</button>
        ))}
        {/* <HashLink to="#rose">go to rose</HashLink>
        <HashLink to="#blue">go to blue</HashLink>
        <HashLink to="#emerald">go to emerald</HashLink>
        <HashLink to="#yellow">go to yellow</HashLink>
        <HashLink to="#slate">go to slate</HashLink> */}
      </div>

      {["rose", "blue", "emerald", "yellow", "slate"].map((v, i) => (
        <div
          key={i}
          id={v}
          ref={(el) => (myRefs.current[v] = el)}
          className={`min-h-screen bg-${v}-200 grid place-content-center`}
        >
          <HashLink to="#top">Go to top</HashLink>
        </div>
      ))}
      {/* <div
        id="blue"
        className="min-h-screen bg-blue-200 grid place-content-center"
      >
        <HashLink to="#top">Go to top</HashLink>
      </div>
      <div
        id="emerald"
        className="min-h-screen bg-emerald-200 grid place-content-center"
      >
        <HashLink to="#top">Go to top</HashLink>
      </div>
      <div
        id="yellow"
        className="min-h-screen bg-yellow-200 grid place-content-center"
      >
        <HashLink to="#top">Go to top</HashLink>
      </div>
      <div
        id="slate"
        className="min-h-screen bg-slate-200 grid place-content-center"
      >
        <HashLink to="#top">Go to top</HashLink>
      </div> */}
    </div>
  );
};

export default Playground;
