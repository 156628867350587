import React from "react";
import { Link } from "react-router-dom";

import Loader from "../general/loader";

const Outlets = ({ franchise, error, id }) => {
  return (
    <div className="franchise-outlets col-span-2 pb-4 sm:pb-0 lg:pt-[22vh] lg:pb-[10vh] lg:w-[90%] w-full mx-auto">
      <div className="pb-10 pt-10 text-center lg:hidden">
        <p className="pre-heading">Discover</p>
        <h1 className="font-bold text-3xl py-3">Outlets</h1>
      </div>
      {franchise ? (
        <div className="wrapper grid gap-4 md:grid-cols-2">
          {franchise.outlets.map((outlet) => (
            <Link
              className="relative"
              to={
                outlet.coming_soon
                  ? `/openingsoon`
                  : `/${franchise.id}/${outlet.id}`
              }
              key={outlet.id}
            >
              <div className="outlet-name mx-[17%] my-[4.5vh] table">
                <div
                  className={
                    // border-2 border-solid  ${
                    // id === "peros" ? "border-red" : "border-green"
                    `
                    } table-cell align-middle`
                  }
                >
                  {/* <img
                      className="mx-auto w-[60%]"
                      src={franchise.logo}
                      alt={`trisara ${outlet.id} logo`}
                    /> */}
                  <p className="mx-auto mt-2 text-center text-[20px] font-bold uppercase tracking-[5px] lg:text-[1.2vw] text-white">
                    {outlet.name}
                  </p>
                </div>
              </div>
              {/* background: do not touch */}
              <img
                className="outlet-img h-[30vh]"
                src={outlet.background_image}
                alt={`trisara ${outlet.id} outlet`}
              />
            </Link>
          ))}
        </div>
      ) : error ? (
        <p className="pt-32 text-center">{error}</p>
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </div>
  );
};

export default Outlets;
