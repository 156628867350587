import React, { useEffect, useState } from "react";

import heroSectionBg from "../assets/images/achievements bg.png";
import mainLogo from "../assets/images/trisara main.png";
import "../styles/achievements.css";
import svgAttributesGenerator from "../utils/svgAttributesGenerator";

const Achievements = () => {
  // Achievements stuffs
  let achievements = [
    {
      title: "Best Franchisee of the Year",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae urna aliquet ultrices. Sed vitae nunc eget nunc aliquam ultrices. Sed vitae nunc eget nunc aliquam ultrices.",
      year: "2019",
      image: "trisara family photo.png",
    },
    {
      title: "Best Franchisee of the Year",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae urna aliquet ultrices. Sed vitae nunc eget nunc aliquam ultrices. Sed vitae nunc eget nunc aliquam ultrices.",
      year: "2019",
      image: "trisara family photo.png",
    },
    {
      title: "Best Franchisee of the Year",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae urna aliquet ultrices. Sed vitae nunc eget nunc aliquam ultrices. Sed vitae nunc eget nunc aliquam ultrices.",
      year: "2020",
      image: "trisara family photo.png",
    },
    {
      title: "Best Franchisee of the Year",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae urna aliquet ultrices. Sed vitae nunc eget nunc aliquam ultrices. Sed vitae nunc eget nunc aliquam ultrices.",
      year: "2022",
      image: "trisara family photo.png",
    },
    {
      title: "Best Franchisee of the Year",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae urna aliquet ultrices. Sed vitae nunc eget nunc aliquam ultrices. Sed vitae nunc eget nunc aliquam ultrices.",
      year: "2021",
      image: "trisara family photo.png",
    },
    {
      title: "Best Franchisee of the Year",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae velit vitae urna aliquet ultrices. Sed vitae nunc eget nunc aliquam ultrices. Sed vitae nunc eget nunc aliquam ultrices.",
      year: "2023",
      image: "trisara family photo.png",
    },
  ];
  achievements = achievements.sort((a, b) => (a.year < b.year ? 1 : -1)); //Sorting the achievements array by year
  let totalYears = [...new Set(achievements.map((item) => item.year))]; //Getting all the unique years from the achievements array

  // SVG stuffs
  let svgPath = svgAttributesGenerator(
    totalYears.length,
    { x: 50, y: 0 },
    100,
    600
  );
  let totalPathLength = svgPath.maxVerticalLength + svgPath.maxHorizontalLength;
  const [pathDrawLength, setPathDrawLength] = useState(totalPathLength);
  const getCoords = (elem) => {
    // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top = box.top + scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  };

  // Event Listener to handle the scroll event in the achievements section
  const achievementsScrollListener = () => {
    let sectionCoords = getCoords(
      document.querySelector("section.achievements")
    );
    // What % down is it?
    var scrollPercentage =
      (document.documentElement.scrollTop - sectionCoords.top + 500) /
      (document.querySelector("section.achievements").scrollHeight -
        document.documentElement.clientHeight +
        500);
    // Length to offset the dashes
    setPathDrawLength(totalPathLength - totalPathLength * scrollPercentage);
  };

  const intersectionCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("visible");
      } else {
        entry.target.classList.remove("visible");
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(intersectionCallback);
    window.addEventListener("scroll", achievementsScrollListener);
    document.querySelectorAll(".achievements-by-year").forEach((item) => {
      observer.observe(item);
    });
  });

  const AchievementCard = ({ achievement }) => {
    return (
      <div className="flex h-[500px] w-[300px] flex-col items-center gap-5 bg-grey bg-opacity-70 p-10 first:w-[400px]">
        <img
          src={require("../assets/images/" + achievement.image)}
          alt="achievement"
          className="h-52 object-cover"
        />
        <div className="achievement-content">
          <h3 className=" text-xl font-bold text-green">{achievement.title}</h3>
          <p className="achievement-description">{achievement.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="achieve">
      <section className="relative flex h-screen w-screen items-center justify-center text-center">
        <img
          src={heroSectionBg}
          alt="landing"
          className="absolute left-0 top-0 -z-10 h-screen w-screen object-cover opacity-30"
        />
        <div>
          <div>
            <img src={mainLogo} alt="trisara logo" className="max-w-[600px]" />
            <h3 className="logo-text">Achievement and Community Footprints</h3>
          </div>
        </div>
      </section>
      <section className="achievements">
        <div className="heading py-20 text-center">
          <h3 className="text-xl font-normal uppercase text-green">
            -Together-
          </h3>
          <h1 className="text-5xl font-semibold uppercase">Our Team</h1>
        </div>
        <div className="flex">
          <svg
            className="achievements-svg"
            width={svgPath.maxHorizontalLength}
            height={svgPath.maxVerticalLength}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {svgPath.circles.map((circle) => (
              <circle
                cx={circle.cx}
                cy={circle.cy}
                r="20"
                className={`achievement-node ${
                  pathDrawLength < circle.displayLength ? "" : "hidden"
                }`}
              />
            ))}
            {svgPath.texts.map((text, index) => (
              <text
                x={text.x}
                y={text.y}
                className={`achievement-text ${
                  pathDrawLength < text.displayLength ? "" : "hidden"
                }`}
              >
                Trisara {totalYears[index]}
              </text>
            ))}
            <path
              d={svgPath.d}
              strokeDasharray={totalPathLength}
              strokeDashoffset={pathDrawLength}
              stroke="black"
              strokeWidth="2"
            />
          </svg>
          <div>
            {totalYears.map((year) => (
              <div className="achievements-by-year flex h-[600px] items-center gap-5">
                {achievements
                  .filter((achievement) => achievement.year === year)
                  .map((achievement, index) => (
                    <AchievementCard key={index} achievement={achievement} />
                  ))}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Achievements;
