import React from "react";
import { useParams } from "react-router-dom";

import Loader from "../../components/general/loader";
import BookHere from "../../components/trisara/bookHere";
import Footer from "../../components/trisara/footer";
import Gallery from "../../components/trisara/gallery";
import Landing from "../../components/trisara/landing";
import LiveMusic from "../../components/trisara/liveMusic";
import Menu from "../../components/trisara/menu";
import { API_URL } from "../../constants";
import useFetch from "../../utils/useFetch";
import NotFound from "../general/notFound";

const Outlet = () => {
  const { id } = useParams();
  const { data: outlet, error } = useFetch(`${API_URL}outlets/${id}`);
  return (
    <div>
      {outlet ? (
        <div className="outlet-home">
          <Landing outlet={outlet} />
          <Menu outlet={outlet} />
          <BookHere outlet={outlet} />
          <Gallery outlet={outlet} />
          <LiveMusic outlet={outlet} />
          <Footer outlet={outlet} />
        </div>
      ) : error ? (
        <NotFound />
      ) : (
        <p className="pt-32 text-center">
          <Loader />
        </p>
      )}
    </div>
  );
};

export default Outlet;
