import React, { useState } from "react";
import { useEffect } from "react";
// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Reservation from "../components/popUps/reservation";
import { API_URL } from "../constants";
import useFetch from "../utils/useFetch";

// for reservation form

const Events = () => {
  // data pull
  const { data: gallery, error } = useFetch(`${API_URL}gallery`);

  // useState initialization variable
  const [bday, setBday] = useState(gallery);
  const [prvt, setPrvt] = useState(gallery);
  const [corporate, setCorporate] = useState(gallery);
  const [family, setFamily] = useState(gallery);
  const [other, setOther] = useState(gallery);

  // data filter
  useEffect(() => {
    setBday(
      gallery?.filter((photo) =>
        photo.image_type.includes("Birthday Celebrations")
      )
    );
    setPrvt(
      gallery?.filter((photo) => photo.image_type.includes("Private Events"))
    );
    setCorporate(
      gallery?.filter((photo) => photo.image_type.includes("Corporate Events"))
    );
    setFamily(
      gallery?.filter((photo) => photo.image_type.includes("Family Events"))
    );
    setOther(
      gallery?.filter((photo) => photo.image_type.includes("Other Events"))
    );
  }, [gallery]);

  // console.log("bday");
  // console.log(bday);
  // console.log("prvt");
  // console.log(prvt);
  // console.log("corporate");
  // console.log(corporate);
  // console.log("family");
  // console.log(family);
  // console.log("other");
  // console.log(other);

  // events array
  const events = [
    {
      data: bday,
      title: "Birthday Celebrations",
    },
    {
      data: prvt,
      title: "Private Events",
    },
    {
      data: corporate,
      title: "Corporate Events",
    },
    {
      data: family,
      title: "Family Events",
    },
    {
      data: other,
      title: "Other Events",
    },
  ];
  // reservation pop up
  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Book an Event",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  // console.log(events);
  return (
    <div className="celebrations-page">
      <div className="background-image text-center">
        <img
          src={require("../assets/images/Trisara Durbarmarg-6556.jpg")}
          alt="Top bg"
        />
        <h1 className="font-bold text-5xl py-3 h-full place-content-center">
          Groups and Events
        </h1>
      </div>
      {/* top part */}
      <div className="events text-center pt-[5vh] m-auto">
        <div className="detail px-2 py-10 m-auto lg:w-[63vw]">
          <p className="pre-heading">EVENTS</p>
          <h1 className="font-bold text-3xl py-3">Never Ending Fun</h1>
          <p className="px-8 py-2">
            Trisara properties have over 10 outlets around Kathmandu including
            Pokhara. Our dedicated team is ready to work closely with you,
            ensuring that every detail aligns with your vision. We offer a range
            of customizable packages to cater to your specific needs and
            preferences. Experience a fusion of first-class service, exquisite
            cuisine, and an awe-inspiring venue, making your event truly
            memorable at Elements. We look forward to helping you create
            unforgettable memories on your special day.
          </p>
          <button className="border-transparent background-color: rgb(255 255 255) ">
            <a
              className="font-semibold text-[15px] cursor-pointer uppercase "
              onClick={() => {
                showReservationPopup();
              }}
            >
              RESERVE EVENT
            </a>
          </button>
        </div>

        {/* image grid */}
        <div className="sm:grid sm:grid-cols-4 sm:grid-rows-3 gap-1 lg:px-40 md:px-20 px-2 mt-8 md:h-[50vh]">
          <div>
            {bday && (
              <img
                className="w-full h-full object-cover sm:rounded-md rounded pb-1.5 md:pb-0"
                src={bday[0].image}
                alt="Birthday Celebrations"
              />
            )}
          </div>
          <div>
            {corporate && (
              <img
                className="w-full h-full object-cover sm:rounded-md rounded pb-1.5 md:pb-0"
                src={corporate[0].image}
                alt="Private Events"
              />
            )}
          </div>
          <div className="col-span-2 row-span-2 col-start-1 row-start-2">
            {family && (
              <img
                className="w-full h-full object-cover sm:rounded-md rounded pb-1.5 md:pb-0"
                src={family[0].image}
                alt="Event"
              />
            )}
          </div>
          <div className="col-span-2 row-span-3 col-start-3 row-start-1">
            {bday && (
              <img
                className="w-full h-full object-cover sm:rounded-md rounded"
                src={bday[1].image}
                alt="Event"
              />
            )}
          </div>
        </div>
      </div>

      {/* events */}
      <div className="group-events text-center pt-[5vh] m-auto">
        <div className="detail px-2 m-auto lg:w-[63vw]">
          <p className="pre-heading">EVENTS</p>
          <h1 className="font-bold text-3xl py-3">Groups and Events</h1>
        </div>

        <div className="grid-container-1 snap-scroll-item">
          {events.map((event, index) => (
            <>
              <a
                onClick={() => {
                  showReservationPopup();
                }}
                href={event.coming_soon ? "/openingsoon" : event.id}
                className="grid-item group relative overflow-hidden text-white hover:text-transparent"
                key={index}
              >
                <div className="grid-image" key={event.id}>
                  <img
                    alt={event.title}
                    src={
                      event.data
                        ? event.data[0].image
                        : require("../assets/images/Trisara-5572.jpg")
                    }
                    className="h-[75vh] lg:w-full w-[100vw] cursor-pointer object-cover grayscale brightness-50 transition-transform duration-[10s] ease-in-out group-hover:scale-125 group-hover:grayscale-0 group-hover:brightness-100"
                  />
                </div>
                <div className="absolute lg:bottom-[30vh] z-10 flex h-full w-full flex-col items-center justify-center gap-5 bg-opacity-40 text-center lg:h-40 lg:bg-opacity-100">
                  <h2 className="font-semibold text-2xl">{event.title}</h2>
                </div>
              </a>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
