import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { API_URL } from "../../constants";
// notification
import { ToastNotification } from "../../utils/ToastNotification";
import useFetch from "../../utils/useFetch";
import Loader from "../general/loader";

const Reservation = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [outlet, setOutlet] = useState("");
  const [noOfPax, setNoOfPax] = useState(0);
  const [noOfChildren, setNoOfChildren] = useState(0);
  const [dateOfArrival, setDateOfArrival] = useState("");
  const [timeOfArrival, setTimeOfArrival] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [others, setOthers] = useState("");

  const { data: outlets_choices, error: outlets_choices_error } = useFetch(
    `${API_URL}outlets/choices/`
  );

  const { data: blocked_dates, error: blocked_dates_error } = useFetch(
    `${API_URL}reservation-blocked-dates/`
  );

  console.log(blocked_dates);

  const OutletOptions = ({ outletChoices }) => {
    let outletFranchises = [
      ...new Set(outletChoices.map((outlet) => outlet.franchise)),
    ];

    let outletOptions = outletFranchises
      .filter((outletFranchises) => outletFranchises !== "Jade City Hotel")
      .map((franchise) => (
        <optgroup label={franchise} key={franchise}>
          {outletChoices
            .filter((outlet) => outlet.franchise === franchise)
            .map((outlet) => (
              <option value={outlet.id} key={`${franchise}-${outlet.id}`}>
                {outlet.name} outlet
              </option>
            ))}
        </optgroup>
      ));
    return outletOptions;
  };

  // Handle date selection
  const handleDateChange = (event) => {
    const date = event.target.value;

    const isBlocked = blocked_dates.some(
      (blocked) =>
        blocked.blocked_date === date && blocked.outlets.includes(outlet)
    );

    if (isBlocked) {
      toast.error(
        `This date is not available for the selected outlet. Please choose a different date.`,
        {
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      setDateOfArrival(""); // Clear the input if blocked date is selected
    } else {
      setDateOfArrival(date); // Allow date selection
    }
  };

  // Handle outlet selection
  const handleOutletChange = (event) => {
    setOutlet(event.target.value);
    setDateOfArrival(""); // Reset date when outlet changes
    console.log(event.target.value);
  };

  const submitReservation = (event) => {
    event.preventDefault();
    let data = JSON.stringify({
      reserved_by: `${firstName} ${lastName}`,
      reserved_location: outlet,
      reserved_date: dateOfArrival,
      reserved_time: timeOfArrival,
      reserver_contact: contactNumber,
      no_of_pax: noOfPax,
      no_of_children: noOfChildren,
      special_requests: specialRequests,
      other_messages: others,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}reserve/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${window.btoa("frontend:WWEQgN9.%AAw]GfR")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        ToastNotification.fire({
          icon: "success",
          // timer: 30000,
          title: "Reservation Successful!",
        });
      })
      .catch((error) => {
        console.log(error);
        ToastNotification.fire({
          icon: "warning",
          // timer: 3000000,
          title: "Reservation Failed!",
        });
      });
  };

  return (
    <form className="md:px-8 px-3 imac:px-28" onSubmit={submitReservation}>
      <ToastContainer />
      {/* FName | LName */}
      <div className="mt-6 grid md:grid-cols-2 md:gap-6">
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="first_name"
            id="first_name"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-5xl imac:leading-[5rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            placeholder=" "
            required
          />
          <label
            htmlFor="first_name"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0  peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            First name <span className="text-rose-500">*</span>
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="text"
            name="last_name"
            id="last_name"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl imac:leading-[5rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder=" "
            required
          />
          <label
            htmlFor="last_name"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            Last name <span className="text-rose-500">*</span>
          </label>
        </div>
      </div>

      {/* Contact Number */}
      <div className="group relative z-0 mb-6 w-full">
        <input
          type="number"
          pattern="[0-9]{10}"
          name="phone"
          id="phone"
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl imac:leading-[5rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
          value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)}
          placeholder=" "
          required
        />
        <label
          htmlFor="phone"
          className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
        >
          Contact Number (98xxxxxxxx) <span className="text-rose-500">*</span>
        </label>
      </div>

      {/* Outlet pick */}
      <div className="group relative z-0 mb-6 w-full">
        <select
          id="underline_select"
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-5xl imac:leading-[5rem] focus:border-gray-600 focus:outline-none focus:ring-0"
          value={outlet}
          onChange={handleOutletChange}
          required
        >
          <option value="" hidden disabled>
            Pick an Outlet *
          </option>
          {outlets_choices ? (
            <OutletOptions outletChoices={outlets_choices} />
          ) : outlets_choices_error ? (
            <option disabled>{outlets_choices_error}</option>
          ) : (
            <option disabled>
              <Loader />
            </option>
          )}
        </select>
      </div>

      {/* Number of people |  */}
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="number"
            name="people"
            id="people"
            min="1"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl imac:leading-[5rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={noOfPax}
            onChange={(e) => setNoOfPax(e.target.value)}
            placeholder=" "
            required
          />
          <label
            htmlFor="people"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            No of Pax <span className="text-rose-500">*</span>
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <input
            type="number"
            name="children"
            id="children"
            min="0"
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl imac:leading-[5rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            value={noOfChildren}
            onChange={(e) => setNoOfChildren(e.target.value)}
            placeholder=" "
          />
          <label
            htmlFor="children"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            No of Children
          </label>
        </div>
      </div>

      {/* Date of Arrival | Time */}
      <div className="grid md:grid-cols-2 md:gap-6">
        <div className="relative mb-6 max-w-sm">
          <input
            datepicker="true"
            type="date"
            id="date"
            value={dateOfArrival}
            onChange={handleDateChange}
            className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl imac:leading-[5rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
            required
          />
          <label
            htmlFor="date"
            className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
          >
            Date of Arrival <span className="text-rose-500">*</span>
          </label>
        </div>
        <div className="group relative z-0 mb-6 w-full">
          <div
            className="relative"
            data-te-timepicker-init
            data-te-input-wrapper-init
          >
            <input
              type="time"
              className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:leading-[5rem] imac:text-4xl  text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
              value={timeOfArrival}
              onChange={(e) => setTimeOfArrival(e.target.value)}
              id="time"
              required
            />
            <label
              htmlFor="time"
              className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
            >
              Select a time <span className="text-rose-500">*</span>
            </label>
          </div>
        </div>
      </div>

      {/* Special Requests */}
      <div className="relative mb-3" data-te-input-wrapper-init>
        <textarea
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl  text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
          rows="3"
          id="specialRequests"
          value={specialRequests}
          onChange={(e) => setSpecialRequests(e.target.value)}
          placeholder=" "
        ></textarea>
        <label
          htmlFor="specialRequests"
          className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
        >
          Special Requests
        </label>
      </div>

      {/* Others */}
      <div className="relative mb-3" data-te-input-wrapper-init>
        <textarea
          className="peer block w-full appearance-none border-0 border-b-2 border-gray-300 bg-transparent px-0 py-2.5 text-sm imac:text-4xl imac:leading-[2rem] text-gray-900 focus:border-gray-600 focus:outline-none focus:ring-0"
          rows="3"
          id="specialRequests"
          value={others}
          onChange={(e) => setOthers(e.target.value)}
          placeholder=" "
        ></textarea>
        <label
          htmlFor="specialRequests"
          className="absolute top-3 -z-10 block origin-[0] -translate-y-6 scale-75 transform text-sm imac:text-5xl text-gray-500 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:font-medium peer-focus:text-gray-500"
        >
          Others
        </label>
      </div>

      <button
        type="submit"
        className="mx-auto block w-full rounded-lg bg-green px-5 py-2.5 text-center text-sm imac:text-4xl font-medium text-gray-50 hover:bg-green-700 focus:outline-none sm:w-auto"
      >
        Submit
      </button>
    </form>
  );
};

export default Reservation;
