import React from "react";
import { Link } from "react-router-dom";

import Footer from "../../components/trisara/footer";

const NotFound = () => {
  const outlet = "";
  return (
    <>
      <div className="mx-auto table h-[100vh] text-center">
        <div className="table-cell align-middle">
          <img
            className="m-auto block w-[90vw] sm:w-96"
            src={require("../../assets/images/notFound-01.png")}
            alt="404"
          />
          <p className="pb-8 pt-4">
            Looks like the page you're craving is on a diet!
          </p>
          <button>
            <Link to="/">Let's Eat</Link>
          </button>
        </div>
      </div>
      <Footer outlet={outlet} />
    </>
  );
};

export default NotFound;
